import { AuthProvider } from '../../entities/Auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import ToastProvider from '../../providers/toast.provider'

const queryClient = new QueryClient()

export const withProviders = (component: React.ReactNode) => (
  <ToastProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          {component}
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </ToastProvider>
) 