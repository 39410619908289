import { useNavigate } from 'react-router-dom';
import { ControlPointTypes } from './ControlPoint.types';
import { setActiveMaterialToCache } from '../../../utils/functions';
import Image from '../../../components/Image';

export const ControlPoint = (props: ControlPointTypes) => {
  const navigation = useNavigate();
  const { setControlPointId, activeMaterialId, activeTaskId, hasMirror } = props;
  return (
    <div
      onClick={() => {
        setControlPointId(props.id);

        setActiveMaterialToCache({
          controlPointId: props.id,
          activeMaterialId,
          activeTaskId,
          hasMirror,
        });

        hasMirror ? navigation(`/task-details/${activeTaskId}`) : navigation(`/login`);
      }}
      className="flex justify-center items-center flex-col gap-[15px] w-[230px] h-auto py-[10px] border border-gray-400 cursor-pointer "
    >
      <Image src={props?.image} alt={props?.description} />

      <div className="w-fill  font-nunito font-bold text-[18px] leading-[19px] text-center">
        {props.description}
      </div>
    </div>
  );
};

export default ControlPoint;
