import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from './view/Partials/Header';
import RoutesComponent from './utils/routes';

function App() {
  document.title = 'ПРОметрика';

  return (
    <>
      <Header />
      <RoutesComponent />
    </>
  );
}

export default App;
