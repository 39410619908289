import { api } from '../../../shared/api/base'
import type { User } from '../types/types'
import type { PrometricaApiResponse, PrometricaApiResponseWithCount } from '../../../shared/api/types/types';
import type { ControlPoint } from '@/entities/ControlPoints/model/types';

const USERS = '/users/'

export const usersApi = {
  getUsersByControlPoint: (controlPointId: ControlPoint['id']): Promise<PrometricaApiResponseWithCount<User[]>> => 
    api.get(`${USERS}?control_point_id=${controlPointId}`),

  getUserById: (userId: User['id']): Promise<PrometricaApiResponse<User[]>> => 
    api.get(`${USERS}${userId}/`),
}