import { api } from '../../../shared/api/base'
import type { PrometricaApiResponseWithCount } from '../../../shared/api/types/types'
import type { Shift, ShiftParams } from '../model/types'

const SHIFT = '/shift/'

export const shiftApi = {
  getShift: (params: ShiftParams): Promise<PrometricaApiResponseWithCount<Shift>> => 
    api.post(SHIFT, params),

  finishShift: (shiftId: Shift['id']) => {
    const url = `${SHIFT}${shiftId}/end/`
    
    return api.post(url)
  }
}