import { api } from '../../../shared/api/base'
import type { PrometricaApiResponse } from '../../../shared/api/types/types'
import type { DefectsPayload } from '../model/types';

const DEFECTS = '/defects/'

export const defectsApi = {
  partiallyDefectMaterial: (payload: DefectsPayload): Promise<PrometricaApiResponse<{quantity: number}[]>> => {
    const url = `${DEFECTS}partially-defect-material/`

    return api.post(url, payload)
  },

  defectItems: (payload: DefectsPayload): Promise<PrometricaApiResponse<{quantity: number}[]>> => {
    const url = `${DEFECTS}defect-items/`

    return api.post(url, payload)
  },

  defectMaterials: (payload: Omit<DefectsPayload, 'length'>): Promise<PrometricaApiResponse<{quantity: number}[]>> => {
    const url = `${DEFECTS}defect-material/`

    return api.post(url, payload)
  },
}