import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../entities/Auth/lib/useAuth';
import { useNavigate } from 'react-router-dom';
import {
  loaderSvg,
  removeLocaleStorage,
  setToLocaleStorage,
} from '../../utils/functions';
import { authApi } from '../../entities/Auth/api/authApi';

export default function Home() {
  const KEY_LOCAL_STORAGE = 'prometrica_token';
  const KEY_LOCAL_STORAGE_REFRESH = 'prometrica_token_refresh';

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, accessToken }: any = useAuth();

  const validateLogin = (username: string, password: string) => {
    if (!username) {
      toast.error('Укажите имя пользователя');
      return false;
    }
    if (!password) {
      toast.error('Укажите пароль');
      return false;
    }
    return true;
  }
  /**
   * Запрос на получения пользователя.
   * Это временный запрос
   */
  const tryToLogin = async () => {
    try {
      setLoader(true);
      removeLocaleStorage(KEY_LOCAL_STORAGE);
      removeLocaleStorage(KEY_LOCAL_STORAGE_REFRESH);
  
      if (!validateLogin(username, password)) {
        setLoader(false);
        return;
      }
  
      const response = await authApi.login({
        username: username,
        password: password,
      });
  
      if (response?.error.length > 0) {
        console.error('Некорректный логин или пароль');
        toast.error('Некорректный логин или пароль');
        setLoader(false);
  
        setUsername('');
        setPassword('');
        return;
      }

      if (response?.data.length > 0) {
        //Установка в local storage
        setToLocaleStorage(KEY_LOCAL_STORAGE, response.data[0].access);
        setToLocaleStorage(KEY_LOCAL_STORAGE_REFRESH, response?.data[0].refresh);
        login(response?.data[0].access);
      }

      navigate('/control-points');
    } catch (error: unknown) {
      console.error(error);
      toast.error('Не удается авторизоваться. Просим обратиться к администратору');
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     if (
  //       accessToken != null &&
  //       accessToken != '' &&
  //       typeof accessToken != 'undefined'
  //     ) {
  //       const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
  //       if (response?.error?.length == 0) {
  //         navigate(`/control-points`);
  //       }
  //     }
  //   })();
  // }, []);

  return (
    <div className="relative top-[59px] w-full h-full flex flex-col gap-[56px] m-auto justify-center items-center py-[14px] bg-[#F8F8F8] ">
      <div className="flex flex-col gap-[32px] h-full w-[360px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Вход
        </h1>
        <div className="flex gap-[20px] flex-col w-full justify-center items-center">
          <div className="flex flex-col gap-[10px] w-full">
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              autoComplete="off"
              className="font-nunito px-[10px] py-[5px] text-[16px]"
              type="text"
              placeholder="Имя пользователя"
            />
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              autoComplete="off"
              className="font-nunito px-[10px] py-[5px] text-[16px]"
              type="password"
              placeholder="Пароль"
            />
          </div>
          <div
            onClick={() => {
              tryToLogin();
            }}
            className={
              'font-nunito w-full cursor-pointer bg-[#4E67EA] text-white hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px]  px-[12px] py-[8px] flex grow text-center justify-center items-center '
            }
          >
            {!loader ? 'Вход' : loaderSvg()}
          </div>
        </div>
      </div>
    </div>
  );
}
