import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginIcon from '../../assets/images/login_icon.png';
import { useAuth } from '../../entities/Auth/lib/useAuth';
import Image from '../../components/Image';
import { usersApi } from '../../entities/User/api/usersApi';

export default function WelcomeUser() {
  const { controlPointId, userId } = useAuth();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState<any>(null);

  const getData = async () => {
    if (!userId) return;

    const response = await usersApi.getUserById(Number(userId))

    if (response?.data?.length > 0) {
      setSelectedData(response?.data[0]);
    }
  };

  const startTask = () => {
    if (
      typeof userId != 'undefined' &&
      typeof controlPointId != 'undefined' &&
      selectedData != null
    ) {
      setTimeout(() => {
        navigate(`/task`);
      }, 4000);
    }
  };

  useEffect(() => {
    startTask();
  }, [selectedData]);

  useEffect(() => {
    getData();
  }, [userId]);

  return (
    <div className="w-full  flex justify-center items-center py-[195px] h-full bg-[#F8F8F8]">
      <div className="flex flex-col justify-center items-center gap-[24px] w-[536px] bg-white p-[52px] rounded-[8px]">
        <Image src={loginIcon} alt="login icon" className="w-[80px] h-[80px]" />

        {!!selectedData && (
          <div className="text-[22px] font-nunito font-semibold text-center w-full px-[35px]">
            Хорошего рабочего дня, {selectedData?.name}!
          </div>
        )}
      </div>
    </div>
  );
}
