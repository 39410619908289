import SelectorsImage from '../assets/images/selectors.png';
import SelectorsImage4 from '../assets/images/Selectors4.png';
import SelectorsImage5 from '../assets/images/Selectors5.png';
import SelectorsImage6 from '../assets/images/Selectors6.png';

export const limitItemsOptions = [
  {
    label: '5',
    value: '5',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '500',
    value: '500',
  },
];

/*Неплановый ремонт -yes
Плановый ремонт - yes
Перевалка прокатного стана более 45 мин - yes
Настройка линии более 15 мин - yes
Ожидание подачи машины - yes
Ожидание сырья - yes
Короткие заготовки - yes
Перекур -yes
Обеденный перерыв - no
*/

export const purposes = [
  {
    id: 1,
    name: 'Плановый ремонт', //yes
    src: SelectorsImage,
  },
  // {
  //   id: 2,
  //   name: "Внеплановый простой",
  //   src: SelectorsImage2,
  // },
  // {
  //   id: 3,
  //   name: "Поломано оборудование",
  //   src: SelectorsImage3,
  // },
  {
    id: 4,
    name: 'Перерыв',
    src: SelectorsImage4,
  },
  {
    id: 5,
    name: 'Отсутствие мест на складе', //yes
    src: SelectorsImage5,
  },
  // {
  //   id: 6,
  //   name: "Качество сырья",
  //   src: null,
  //   icon: 'stone'
  // },
  {
    id: 7,
    name: 'Перевалка прокатного стана более 45 мин', // yes
    src: null,
    icon: 'caravan',
  },
  {
    id: 8,
    name: 'Короткие заготовки', //yes
    src: null,
    icon: 'plastic',
  },
  {
    id: 9,
    name: 'Настройка линии более 15 мин', // yes
    src: null,
    icon: 'penruler',
  },
  {
    id: 10,
    name: 'Неплановый ремонт', // yes
    src: SelectorsImage,
  },
  {
    id: 11,
    name: 'Ожидание подачи машины', //yes
    src: null,
    icon: 'clone',
  },
  {
    id: 12,
    name: 'Ожидание сырья', // yes
    src: null,
    icon: 'stone',
  },
  // {
  //   id: 13,
  //   name: "Отсутствие заказа в 1С",
  //   src: null,
  //   icon: 'cart'
  // },
  // {
  //   id: 14,
  //   name: "Отсутствие складских площадей",
  //   src: null,
  //   icon: 'warehouse'
  // },
  // {
  //   id: 15,
  //   name: "Проблемы с 1С",
  //   src: SelectorsImage3,
  // },
  // {
  //   id: 16,
  //   name: "Производство заказа с длиной листов менее 3м",
  //   src: null,
  //   icon: 'th'

  // },
  {
    id: 17,
    name: 'Другое',
    src: SelectorsImage6,
  },
];


export const ShiftsTimeLino: {
  [shiftName: string]: {
    startHour: number,
    endHour: number
  }
} = {
  first: {
    startHour: 8,
    endHour: 20
  },
  second: {
    startHour: 20,
    endHour: 8
  }
}
