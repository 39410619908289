import {api} from '../../../shared/api/base'
import { AuthLoginRequest, AuthLoginResponse, AuthRefreshTokenRequest } from '../model/types'
import { PrometricaApiResponse } from '../../../shared/api/types/types'

const API_TOKEN = '/api/token/'

export const authApi = {
  login: (params: AuthLoginRequest): Promise<PrometricaApiResponse<AuthLoginResponse[]>> => 
    api.post(API_TOKEN, params),

  refresh: (params: AuthRefreshTokenRequest): Promise<PrometricaApiResponse<AuthLoginResponse[]>> =>
    api.post(`${API_TOKEN}refresh/`, params), 
}