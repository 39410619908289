import { useLocalStorage } from '../../../shared/lib/hooks/useLocalStorage'

export const useAuthStorage = () => {
  const [accessToken, setAccessToken] = useLocalStorage('prometrica_token', null)
  const [refreshToken, setRefreshToken] = useLocalStorage('prometrica_token_refresh', null)
  const [controlPointId, setControlPointId] = useLocalStorage('control_point_id', null)
  const [userId, setUserId] = useLocalStorage('user_id', null)
  const [channelWork, setChannelWork] = useLocalStorage('channel_work', null)
  const [typeRoute, setTypeRoute] = useLocalStorage('type_route', null)

  return {
    accessToken,
    refreshToken,
    controlPointId,
    userId,
    channelWork,
    typeRoute,
    setAccessToken,
    setRefreshToken,
    setControlPointId,
    setUserId,
    setChannelWork,
    setTypeRoute
  }
} 