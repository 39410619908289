import { api } from '../../../shared/api/base'
import type { PrometricaApiResponse } from '../../../shared/api/types/types'
import type { DowntimePayload, DowntimeResponse } from '../model/types';
import type { Task } from '@/entities/Task/model/types';
import type { User } from '@/entities/User/types/types';

const DOWNTIME = '/downtime/'

export const downtimeApi = {
  downtimeTask: (payload: DowntimePayload): Promise<PrometricaApiResponse<DowntimeResponse>> => {
    return api.post(DOWNTIME, payload)
  },

  finishDowntimeTask: (downtimeId: number, payload: {user_id: User['id'], task_id: Task['id']}): Promise<PrometricaApiResponse<DowntimeResponse>> => {
    const url = `${DOWNTIME}${downtimeId}/end/`

    return api.post(url, payload)
  },
}