import { useState } from 'react';
import StoneIcon from '../../../icons/StoneIcon';
import CaravanIcon from '../../../icons/CaravanIcon';
import PlasticIcon from '../../../icons/PlasticIcon';
import PenRulerIcon from '../../../icons/PenRulerIcon';
import CloneIcon from '../../../icons/CloneIcon';
import CartIcon from '../../../icons/CartIcon';
import WarehouseIconIcon from '../../../icons/WarehouseIcon';
import ThIcon from '../../../icons/ThIcon';
import { useAuth } from '../../../entities/Auth';
import { toast } from 'react-toastify';
import { loaderSvg } from '../../../utils/functions';
import { purposes } from '../../../utils/constants';
import { downtimeApi } from '../../../entities/Downtime/api/downtimeApi';

export default function ModalStopPurpose({
  selectedData,
  modalStopPurpose,
  setModalPurpose,
  setDownTimeDate,
  pauseTimeAfterModalDone,
  userId,
}: any) {
  const { refreshToken }: any = useAuth();
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [detailText, setDetailText] = useState('');

  const sendPurpose = async () => {
    if (typeof selectedItem == 'undefined') {
      return;
    }
    setLoader(true);
    pauseTimeAfterModalDone();

    const response = await downtimeApi.downtimeTask({
      task_id: selectedData?.id,
      reason: selectedItem?.id == 17 ? detailText : selectedItem?.name,
      user_id: userId,
    })

    if (response?.data) {
      setDownTimeDate(response?.data);
    }

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    setLoader(false);
    setModalPurpose(!modalStopPurpose);
  };

  const returnIcon = (icon: string) => {
    switch (icon) {
      case 'stone':
        return <StoneIcon w={27} h={27} />;
      case 'caravan':
        return <CaravanIcon w={27} h={27} />;
      case 'plastic':
        return <PlasticIcon w={27} h={27} />;
      case 'penruler':
        return <PenRulerIcon w={27} h={27} />;
      case 'clone':
        return <CloneIcon w={27} h={27} />;
      case 'cart':
        return <CartIcon w={27} h={27} />;
      case 'warehouse':
        return <WarehouseIconIcon w={27} h={27} />;
      case 'th':
        return <ThIcon w={27} h={27} />;
    }
  };

  return (
    <div className="absolute left-0 right-0 top-0 z-50 flex h-[calc(100%-1rem)] max-h-full w-full items-center justify-center  overflow-y-auto  overflow-x-hidden text-center ">
      <div className="relative max-h-full w-full max-w-[591px] p-4">
        <div className="relative rounded-[8px] bg-white shadow-lg flex flex-col gap-[20px] ">
          <div className="w-full flex justify-between items-center px-[12px] py-[5px]">
            <h2 className="text-[22px] px-[5px] py-[10px] font-semibold font-nunito  text-[#252628] ">
              Выберите причину остановки
            </h2>
            <button
              onClick={() => setModalPurpose(!modalStopPurpose)}
              type="button"
              className="transition-all ease-linear ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <div className="w-full justify-center items-center px-[5px]">
            <div className="flex gap-[16px] flex-wrap w-full justify-center h-[370px] overflow-y-auto">
              {purposes?.length > 0 &&
                purposes?.map((value: any, index: number) => {
                  return (
                    <div
                      onClick={() => setSelectedItem(value)}
                      key={index}
                      className={
                        'flex cursor-pointer transition-all ease-linear hover:bg-blue-200 p-[24px] w-[240px] gap-[12px] rounded-[4px] border border-gray-200 ' +
                        (typeof selectedItem != 'undefined' &&
                        selectedItem != null &&
                        selectedItem?.id === value?.id
                          ? 'bg-blue-300'
                          : '')
                      }
                    >
                      <div className="flex w-[48px] h-[48px]  rounded-[4px]">
                        {value?.src != null ? (
                          <img
                            src={value?.src}
                            className="bg-no-repeat min-w-[48px] min-h-[48px]"
                          />
                        ) : (
                          <div className="bg-[#f1f3fe] w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                            {returnIcon(value?.icon)}
                          </div>
                        )}
                      </div>
                      <p className="px-[5px] text-[14px] font-semibold text-start">
                        {value?.name}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          {selectedItem?.id === 17 ? (
            <div className="w-full  flex gap-[10px] px-[30px] py-[10px]">
              <textarea
                placeholder="Описание"
                cols={2}
                rows={2}
                value={detailText}
                onChange={(e) => setDetailText(e.target.value)}
                className="w-full p-[8px] border rounded-[4px] text-[14px] font-nunito"
              ></textarea>
            </div>
          ) : null}
          <div className="w-full  flex gap-[10px] px-[30px] py-[10px] transition-all ease-linear">
            <div
              onClick={() => setModalPurpose(!modalStopPurpose)}
              className="py-[10px] transition-all ease-linear active:bg-[#5870ed] h-[56px] hover:text-white hover:bg-[#768aed] w-1/2 border border-[#cfcfcf] rounded-[4px]  flex flex-col justify-center items-center"
            >
              <div className="select-none   py-[13px] w-1/2  flex flex-col justify-center items-center cursor-pointer">
                <p className="select-none font-nunito text-[14px]">Отменить</p>
              </div>
            </div>
            <div
              onClick={() => sendPurpose()}
              className={
                'transition-all ease-linear select-none py-[10px] rounded-[4px] h-[56px] w-1/2 bg-[#4E67EA] active:bg-[#5870ed] hover:bg-[#768aed] flex flex-col justify-center items-center cursor-pointer ' +
                (selectedItem == null || typeof selectedItem == 'undefined'
                  ? 'bg-gray-600 disabled opacity-20 cursor-not-allowed '
                  : '')
              }
            >
              <p className="select-none font-nunito text-[14px]  text-white">
                {loader == true ? loaderSvg() : 'Подвердить'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
