import ControlPoint from '../Home/ControlPoint/ControlPoint';
import { useAuth } from '../../entities/Auth/lib/useAuth';
import { loaderSvg } from '../../utils/functions';
import { controlPointsApi } from '../../entities/ControlPoints/api/controlPointsApi';
import { useEffect, useState } from 'react';
import type { ControlPoint as ControlPointType } from '../../entities/ControlPoints/model/types';
export default function ControlPoints() {
  const [controlPointsData, setControlPointsData] = useState<any>([]);
  const [isControlPointsLoading, setIsControlPointsLoading] = useState<boolean>(false);
  const { setControlPointId, controlPointId }: any = useAuth();
  
  const tryGetControlPoints = async () => {
    try {
      setIsControlPointsLoading(true);

      const response = await controlPointsApi.getControlPoints();

      setControlPointsData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsControlPointsLoading(false);
    }
  }

  useEffect(() => {
    tryGetControlPoints();
  }, []);

  return (
    <div className="relative top-[59px] w-full h-auto min-h-full flex flex-col gap-[56px] m-auto justify-start items-center py-[14px] bg-[#F8F8F8]">
      <div className="flex flex-col gap-[32px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Выберите контрольную точку
        </h1>
        <div className="flex w-full justify-center items-center">
          {isControlPointsLoading ? (
            loaderSvg()
          ) : (
            <div className="flex flex-wrap max-w-[900px] gap-[35px] justify-center overflow-y-auto">
              {controlPointsData?.length > 0 &&
                controlPointsData.map((value: ControlPointType) => {
                  return (
                    <ControlPoint
                      id={value.id}
                      description={value.description}
                      image={value.image}
                      key={value?.id}
                      activeMaterialId={value.active_material_id}
                      activeTaskId={value.active_task_id}
                      setControlPointId={setControlPointId}
                      controlPointId={controlPointId}
                      hasMirror={!!value.mirror_id}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
