import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../entities/Auth/lib/useAuth';
import { toast } from 'react-toastify';
import SelectNew, { SelectOption } from '../../components/SelectNew';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { usersApi } from '../../entities/User/api/usersApi';
import { User } from '../../entities/User/types/types';
import { shiftApi } from '../../entities/Shift/api/shiftApi';
import { AxiosError } from 'axios';
export const shifts: any = {
  first: 'Ⅰ смена',
  second: 'Ⅱ смена'
};

export default function Login() {
  const {
    accessToken,
    refreshToken,
    controlPointId,
    userId,
    setUserId,
    channelWork,
    setChannelWork,
  }: any = useAuth();

  const navigate = useNavigate();
  const [data, setData] = useState<User[]>([]);
  const [selectedData, setSelectedData] = useState<User>();
  const [cChannelWork, sSetChannelWork] = useState<string>('');
  const [_, setShiftId] = useState<any>();

  const resolvePreSelectedShiftFromCurrentTime = () => {
    const currentDate = new Date()

    if (currentDate.getHours() >= 6 && currentDate.getHours() <= 18) {
      return 'first'
    } else {
      return 'second'
    }
  }

  const getData = async () => {
    const response = await usersApi.getUsersByControlPoint(controlPointId)

    if (response?.count > 0) {
      setData(response?.data);
      setUserId(response?.data[0]?.id);

      const currProposedShiftKey = resolvePreSelectedShiftFromCurrentTime()
      sSetChannelWork(currProposedShiftKey)
      setChannelWork(currProposedShiftKey)
    }

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [accessToken, controlPointId]);

  const startShift = async () => {
    try {
      const response = await shiftApi.getShift({
        user_id: userId,
        shift_number: channelWork,
      })

      if (response?.error?.length > 0) {
        toast.warning(response?.error[0]);
      }
      
      
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.warning(error?.response?.data?.error[0]);
      }
    }

    navigate(`/welcome-user`);
  };

  const options = useMemo<SelectOption[]>(() => 
    data.map((value: User) => ({
      id: value.id.toString(),
      name: value.name
  })), [data])

  const defaultValue = useMemo<SelectOption | undefined>(() => 
    options?.length > 0 ? options[0] : undefined, [options])

  const onChange = useCallback((value: SelectOption) => {
    setSelectedData(data.find((user) => user.id === parseInt(value.id)))
    setUserId(value?.id);
  }, [data, setUserId])

  return (
    <div className="w-full bg-[#EEEEEE] flex justify-center items-center py-[195px] h-full">
      <div className="flex flex-col gap-[32px] w-[536px] bg-white p-[32px] rounded-[8px]">
        <div
          data-testid="login-container"
          className="text-[28px] font-nunito font-semibold text-center"
        >
          Выберите пользователя
        </div>
        <div className="flex flex-col gap-[24px]  w-full">
          <SelectNew
            options={options}
            onChange={onChange}
            defaultValue={defaultValue}
            zIndex={10}
          />

          <div className="w-full flex gap-[10px] text-[#C0C1C3] text-[16px] font-nunito ">
            {selectedData && selectedData.shift?.length > 0
              ? selectedData?.shift?.map((value: any) => (
                  <PrimaryButton
                    label={shifts[value?.shift_number]}
                    isSelected={cChannelWork === value?.shift_number}
                    onClick={() => {
                      sSetChannelWork(value?.shift_number);
                      setChannelWork(value?.shift_number);
                      setShiftId(value?.id);
                    }}
                  />
                ))
              : Object.keys(shifts).map((shiftKey) => (
                  <PrimaryButton
                    label={shifts[shiftKey]}
                    isSelected={cChannelWork === shiftKey}
                    onClick={() => {
                      sSetChannelWork(shiftKey);
                      setChannelWork(shiftKey);
                    }}
                  />
                ))}
          </div>

          {!!cChannelWork ? (
            <PrimaryButton
              onClick={() => startShift()}
              label="Продолжить"
              isSelected={!!selectedData}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
