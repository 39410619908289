import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import { toast } from 'react-toastify'

// Добавляем расширение для AxiosRequestConfig
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean;
}

export const BASE_URL = process.env.REACT_APP_API_URL

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false,
})

// Интерцептор для добавления токена
api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('prometrica_token')

    let parsedToken = token
    try {
      if (token && token.startsWith('"') && token.endsWith('"')) {
        parsedToken = JSON.parse(token)
      }
    } catch {
      parsedToken = token
    }

    if (parsedToken && config.headers) {
      config.headers.Authorization = `Bearer ${parsedToken}`
    }
    
    return config
  },
  (error) => Promise.reject(error)
)

// Интерцептор для обработки ошибок
api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig
    // Проверяем, что это 401 ошибка и запрос не помечен как повторный
    if (error.response?.status === 401 && !originalRequest._isRetry) {
      try {
        // Помечаем запрос, чтобы избежать зацикливания
        originalRequest._isRetry = true
        
        const refreshToken = localStorage.getItem('prometrica_token_refresh')
        let parsedRefreshToken = refreshToken
        
        try {
          if (refreshToken && refreshToken.startsWith('"') && refreshToken.endsWith('"')) {
            parsedRefreshToken = JSON.parse(refreshToken)
          }
        } catch {
          parsedRefreshToken = refreshToken
        }
        
        if (parsedRefreshToken) {
          const response = await axios.post(`${BASE_URL}api/token/refresh/`, {
            refresh: parsedRefreshToken
          })

          const refreshedAccessToken = response.data.data[0].access
          
          if (refreshedAccessToken) {
            // Сохраняем новый токен
            localStorage.setItem('prometrica_token', JSON.stringify(refreshedAccessToken))
            
            // Обновляем заголовок в оригинальном запросе
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${refreshedAccessToken}`
            }
            
            // Повторяем оригинальный запрос с новым токеном
            return axios(originalRequest)
          }
        }
      } catch (e) {
        console.error('Ошибка обновления токена:', e)
        localStorage.removeItem('prometrica_token')
        localStorage.removeItem('prometrica_token_refresh')
        toast.error('Сессия истекла. Пожалуйста, войдите снова')
        window.location.href = '/'
        // Можно добавить редирект на страницу логина
      }
    }
    
    // Для других ошибок
    const errorData = error.response?.data as { message?: string, error?: string[] }
    const errorMessage = errorData?.message || (errorData?.error && errorData.error[0]) || 'Произошла ошибка'
    toast.error(errorMessage)
    return Promise.reject(error)
  }
)