/**
 * Утилита для формирования строки query параметров
 * @param params - Объект с параметрами
 * @returns Строка query параметров
 */
export const buildQueryParams = (params: Record<string, any>): string => {
  if (!params || typeof params !== 'object') {
    return '';
  }

  const queryParts = Object.entries(params)
    .filter(([_, value]) => {
      // Фильтруем undefined, null и пустые строки
      if (value === undefined || value === null) {
        return false;
      }
      
      // Проверка на пустую строку
      if (typeof value === 'string' && value.trim() === '') {
        return false;
      }
      
      return true;
    })
    .map(([key, value]) => {
      // Для массивов создаем несколько параметров с одинаковым ключом
      if (Array.isArray(value)) {
        return value
          .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(String(item))}`)
          .join('&');
      }
      
      // Для boolean, чтобы было "true"/"false" а не "1"/"0"
      if (typeof value === 'boolean') {
        return `${encodeURIComponent(key)}=${value}`;
      }
      
      return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    });

  return queryParts.join('&');
}; 