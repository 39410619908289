import { api } from "../../../shared/api/base"
import { buildQueryParams } from "../../../shared/lib/url"
import type { PrometricaApiResponse, PrometricaApiResponseWithCount } from "@/shared/api/types/types"
import type { MetricsData, ReadyItemsPayload, Task, TaskChangeMaterialPayload, TaskData, TaskFlatsheetPayload, TaskMetricsQuery, TaskQuery } from "../model/types"
import type { ControlPoint, User } from "@/types/models/task"
import type { MaterialOperationsDone } from "../../../entities/Material/model/types"

const TASKS = '/tasks/'

export const tasksApi = {
  getTasks: (query: TaskQuery): Promise<PrometricaApiResponseWithCount<TaskData>> => {
    const queryParams = buildQueryParams({
      control_point_id: query.control_point_id,
      offset: query.offset,
      limit: query.limit,
      active_today: query.active_today,
      text: query.text,
      ordering: query.ordering,
      status: query.status
    })

    const url = queryParams ? `${TASKS}get-tasks/?${queryParams}` : `${TASKS}get-tasks/`
    return api.post(url, {})
  },

  getMetrics: (query: TaskMetricsQuery): Promise<PrometricaApiResponse<MetricsData[]>> => {
    const queryParams = buildQueryParams({
      control_point_id: query.control_point_id,
      offset: query.offset,
      limit: query.limit,
      shift: query.shift,
      end_time_gte: query.end_time_gte,
      end_time_lte: query.end_time_lte,
    })

    const url = queryParams ? `${TASKS}get-metrics/?${queryParams}` : `${TASKS}get-metrics/`
    return api.get(url)
  },

  getTask: (taskId: Task['id'], controlPointId: ControlPoint['id'] | null): Promise<PrometricaApiResponse<Task[]>> => {
    const queryParams = buildQueryParams({
      control_point_id: controlPointId,
    })

    const url = queryParams ? `${TASKS}${taskId}/?${queryParams}` : `${TASKS}${taskId}/`
    return api.get(url)
  },

  addReadyItems: (taskId: Task['id'], payload: ReadyItemsPayload): Promise<PrometricaApiResponse<ReadyItemsPayload[]>> => {
    const url = `${TASKS}${taskId}/add-ready-items/`

    return api.post(url, payload)
  },

  startTask: (taskId: Task['id'], userId: User['user_id']): Promise<PrometricaApiResponse<{ready_items: boolean}[]>> => {
    const url = `${TASKS}${taskId}/start-task/`

    return api.post(url, {user_id: userId})
  },

  finishTask: (taskId: Task['id'], userId: User['user_id']): Promise<PrometricaApiResponse<MaterialOperationsDone[]>> => {
    const url = `${TASKS}${taskId}/end-task/`

    return api.post(url, {user_id: userId})
  },

  createTaskFlatsheet: (payload: TaskFlatsheetPayload): Promise<PrometricaApiResponse<any[]>> => {
    const url = `${TASKS}/create-task-flat-sheet/`

    return api.post(url, payload)
  },

  resetTask: (taskId: Task['id']): Promise<PrometricaApiResponse<any[]>> => {
    const url = `${TASKS}${taskId}/reset-task/`

    return api.post(url)
  },

  getNextOrPreviousTask: (taskId: Task['id'], marker: boolean) => {
    const queryParams = buildQueryParams({
      marker,
    })

    const url = queryParams ? `${TASKS}${taskId}/get-next-or-previous-task/?${queryParams}` : `${TASKS}${taskId}/get-next-or-previous-task/`

    return api.get(url)
  },

  changeMaterial: (taskId: Task['id'], payload: TaskChangeMaterialPayload): any => {
    const url = `${TASKS}${taskId}/change-material/`

    return api.post(url, payload)
  }

  // /change-material/

  // get-next-or-previous-task/

  // endTask: (taskId: number, userId: number) => {
  //   const queryParams = buildQueryParams({ user_id: userId })
  //   const url = queryParams ? `${TASKS}${taskId}/end-task/?${queryParams}` : `${TASKS}${taskId}/end-task/`
  //   return api.post<PrometricaApiResponseWithCount<TaskData>>(url, {})
  // },

  // getTaskDetails: (taskId: number, controlPointId: number) => {
  //   const queryParams = buildQueryParams({ control_point_id: controlPointId })
  //   const url = queryParams ? `${TASKS}${taskId}/?${queryParams}` : `${TASKS}${taskId}/`
  //   return api.get<PrometricaApiResponseWithCount<TaskData>>(url)
  // },
}