import { api } from '../../../shared/api/base'
import { buildQueryParams } from '../../../shared/lib/url/buildQueryParams'
import type { PrometricaApiResponse, PrometricaApiResponseWithCount } from '../../../shared/api/types/types'
import type { Material, MaterialData, MaterialsPayload, UseMaterialPayload } from '../model/types'
import type { Task } from '@/entities/Task/model/types'

const MATERIALS = '/materials/'

export const materialApi = {
  getMaterials: (payload: MaterialsPayload): Promise<PrometricaApiResponseWithCount<MaterialData[]>> => {
    const queryParams = buildQueryParams({
      ...payload,
    })

    const url = queryParams ? `${MATERIALS}?${queryParams}` : `${MATERIALS}`

    return api.get(url)
  },

  useMaterial: (payload: UseMaterialPayload): Promise<PrometricaApiResponse<Task[] | {quantity: number}[]>> => 
    api.post(`${MATERIALS}use-material/`, payload),
  
  getMaterial: (materialId: Material['material_id']): Promise<PrometricaApiResponse<MaterialData[]>> =>
    api.get(`${MATERIALS}${materialId}/`),

  materialIdle: (materialId: Material['material_id']): Promise<PrometricaApiResponse<any>> =>
    api.post(`${MATERIALS}${materialId}/idle-material/`)
}