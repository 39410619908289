import React, { useState } from 'react';
import SelectNew, { SelectOption } from '../../components/SelectNew';
import Button from '../../components/buttons/Button';
import { useAuth } from '../../entities/Auth';
import { toast } from 'react-toastify';
import Counter from '../../components/Counter';
import Checkbox from '../../components/Checkbox';
import { defectsApi } from '../../entities/Defects/api/defectsApi';
import type { Material } from '../../entities/Material/model/types';

const MATERIAL_DEFECT_ID = 'MATERIAL';
const ITEM_DEFECT_ID = 'ITEM';

const DEFECT_TYPES: SelectOption[] = [
  { id: MATERIAL_DEFECT_ID, name: 'Брак металла' },
  { id: ITEM_DEFECT_ID, name: 'Брак готовой продукции' },
];

const MATERIAL_DEFECTS: SelectOption[] = [
  { id: '1', name: 'Сварной шов' },
  { id: '2', name: 'Рванина (разрыв металла)' },
  { id: '3', name: 'Круглое отверстие' },
  { id: '4', name: 'Окись' },
  { id: '5', name: 'Непрооцинковка' },
  { id: '6', name: 'Некачественное прокрытие' },
];
const READY_ITEM_DEFECTS: SelectOption[] = [
  { id: '1', name: 'Хлоп (замятие профиля)' },
  { id: '2', name: 'Отклонение по длине' },
  { id: '3', name: 'Нет полки' },
  { id: '4', name: 'Нет замка' },
  { id: '5', name: 'Листы застряли в стане' },
];

export default function DefectWindow({
  taskId,
  materialId,
  onSubmit,
  setDefectWindowModalShow,
}: {
  taskId: string;
  materialId: number | null;
  onSubmit: (isNewMaterialNeeded: boolean) => void;
  setDefectWindowModalShow: (value: boolean) => void;
}) {
  const { accessToken, refreshToken }: any = useAuth();

  const [currentDefectType, setCurrentDeffectType] =
    useState<SelectOption | null>(null);

  const [currentDefect, setCurrentDeffect] = useState<SelectOption | null>(
    null
  );
  const [defectLength, setDefectLength] = useState(0);
  const [isWholeMaterialDefected, setIsWholeMaterialDefected] = useState(false);

  const sendPartialMaterialDefect = async (
    taskId: string,
    materialId: number | null,
    length: number,
    description?: string
  ): Promise<number | undefined> => {
    if (!materialId) return

    const response = await defectsApi.partiallyDefectMaterial({
      task_id: taskId,
      material_id: materialId,
      description: description,
      length: length,
    })
    
    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      //Проверка токена
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    return response?.data[0]?.quantity;
  };

  const sendMaterialDefect = async (
    taskId: string,
    materialId: Material['material_id'] | null,
    description?: string
  ) => {
    if (!materialId) return

    const response = await defectsApi.defectMaterials({    
      task_id: taskId,
      material_id: materialId,
      description: description,
    })

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);
    }
  };

  const sendItemsDefect = async (
    taskId: string,
    materialId: Material['material_id'] | null,
    length: number,
    description?: string
  ): Promise<number | undefined> => {
    if (!materialId) return

    const response = await defectsApi.defectItems({
      task_id: taskId,
      material_id: materialId,
      description: description,
      length: length,
    })

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);
    }

    return response?.data[0]?.quantity;
  };

  const saveDefect = async () => {
    let possibleItemsNumber;

    if (currentDefectType?.id === MATERIAL_DEFECT_ID) {
      if (isWholeMaterialDefected) {
        possibleItemsNumber = 0;
        await sendMaterialDefect(taskId, materialId, currentDefect?.name);
      } else {
        possibleItemsNumber = await sendPartialMaterialDefect(
          taskId,
          materialId,
          defectLength,
          currentDefect?.name
        );
      }
    } else if (currentDefectType?.id === ITEM_DEFECT_ID) {
      possibleItemsNumber = await sendItemsDefect(
        taskId,
        materialId,
        defectLength,
        currentDefect?.name
      );
    }

    setDefectWindowModalShow(false);
    onSubmit(possibleItemsNumber === 0);
  };

  return (
    <div className="text-[32px]">
      Сообщение о браке
      <div className="text-[20px] mt-[24px]">
        Выберите тип брака
        <SelectNew
          options={DEFECT_TYPES}
          onChange={setCurrentDeffectType}
          zIndex={10}
        />
      </div>
      <div className="text-[20px] mt-[32px]">
        Выберите вид брака
        <SelectNew
          options={
            currentDefectType?.id === MATERIAL_DEFECT_ID
              ? MATERIAL_DEFECTS
              : READY_ITEM_DEFECTS
          }
          onChange={setCurrentDeffect}
          disabled={!currentDefectType}
        />
      </div>
      <div className="w-full justify-start items-start flex flex-row gap-[5px] mt-[44px]">
        <p className="text-[20px]" style={{ lineHeight: '20px' }}>
          Количество бракованного металла (погонных метров)
        </p>
        <Counter
          upCount={() => setDefectLength(defectLength + 1)}
          downCount={() => setDefectLength(defectLength - 1)}
          value={defectLength}
          setValue={setDefectLength}
        />
      </div>
      {currentDefectType?.id === MATERIAL_DEFECT_ID && (
        <Checkbox
          checked={isWholeMaterialDefected}
          setCheckbox={setIsWholeMaterialDefected}
        />
      )}
      <div className="flex w-full gap-[8px] mt-[32px]">
        <Button
          text="Отменить"
          color="secondary"
          onClick={() => {
            setDefectWindowModalShow(false);
          }}
        />

        <Button
          text="Подтвердить"
          onClick={saveDefect}
          disabled={!(currentDefectType && currentDefect)}
        />
      </div>
    </div>
  );
}
