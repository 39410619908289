import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../model/authContext'
import { useAuthStorage } from '../lib/useAuthStorage'
// import { authApi } from '../api/authApi'

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const storage = useAuthStorage()

  const login = (token: string) => {
    storage.setAccessToken(token)
  }

  const logout = () => {
    storage.setAccessToken(null)
    storage.setUserId(null)
    storage.setChannelWork(null)
    storage.setTypeRoute(null)
    storage.setControlPointId(null)
    navigate('/', { replace: true })
  }

  // const refreshToken = async () => {
  //   const response = await authApi.checkAuth(storage.accessToken)
  //   if (response?.data?.error?.length > 0) {
  //     const refreshResponse = await authApi.refresh(storage.refreshToken)
  //     if (refreshResponse?.data?.access) {
  //       storage.setAccessToken(refreshResponse.data.access)
  //       login(refreshResponse.data.access)
  //       window.location.reload()
  //     } else {
  //       navigate('/')
  //     }
  //   }
  // }

  const value = useMemo(
    () => ({
      accessToken: storage.accessToken,
      userId: storage.userId,
      controlPointId: storage.controlPointId,
      channelWork: storage.channelWork,
      typeRoute: storage.typeRoute,
      login,
      logout, 
      // refreshToken,
      setControlPointId: storage.setControlPointId,
      setUserId: storage.setUserId,
      setChannelWork: storage.setChannelWork,
      setTypeRoute: storage.setTypeRoute
    }),
    [
      storage.accessToken,
      storage.controlPointId,
      storage.userId,
      storage.channelWork,
      storage.typeRoute
    ]
  )

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
} 